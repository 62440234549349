<template>
	<div class="auth-wrapper auth-v2">
		<b-row class="auth-inner m-0">
			<!-- Left Text-->
			<b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
				<div
					class="w-100 d-lg-flex align-items-center justify-content-center px-5"
				>
					<b-img fluid :src="imgUrl" alt="Login V2" />
				</div>
			</b-col>
			<!-- /Left Text-->

			<!-- Login-->
			<b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
				<b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
					<b-card-title title-tag="h2" class="font-weight-bold mb-1">
						Welcome to Emoty! 👋
					</b-card-title>

					<!-- form -->
					<validation-observer ref="loginValidation">
						<b-form class="auth-login-form mt-2" @submit.prevent>
							<!-- email -->
							<b-form-group label="Email" label-for="login-email">
								<validation-provider
									#default="{ errors }"
									name="Email"
									rules="required|email"
								>
									<b-form-input
										id="login-email"
										v-model="userEmail"
										:state="errors.length > 0 ? false : null"
										name="login-email"
										placeholder="john@example.com"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- forgot password -->
							<b-form-group>
								<validation-provider
									#default="{ errors }"
									name="Password"
									rules="required"
								>
									<b-input-group
										class="input-group-merge"
										:class="errors.length > 0 ? 'is-invalid' : null"
									>
										<b-form-input
											id="login-password"
											v-model="password"
											:state="errors.length > 0 ? false : null"
											class="form-control-merge"
											:type="passwordFieldType"
											name="login-password"
											placeholder="············"
										/>
										<b-input-group-append is-text>
											<feather-icon
												class="cursor-pointer"
												:icon="passwordToggleIcon"
												@click="togglePasswordVisibility"
											/>
										</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- submit buttons -->
							<b-button type="submit" variant="primary" block @click="login">
								Sign in
							</b-button>
						</b-form>
					</validation-observer>

					<div class="text-center mt-2">
						<span>New on our platform? </span>
						<b-link :to="{ name: 'vendor-register' }">
							<span>&nbsp;Create an account</span>
						</b-link>
					</div>

					<div />
				</b-col>
			</b-col>
			<!-- /Login-->
		</b-row>
	</div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
	BRow,
	BCol,
	BLink,
	BFormGroup,
	BFormInput,
	BInputGroupAppend,
	BInputGroup,
	BCardTitle,
	BImg,
	BForm,
	BButton,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store/index';

export default {
	components: {
		BRow,
		BCol,
		BLink,
		BFormGroup,
		BFormInput,
		BInputGroupAppend,
		BInputGroup,
		BCardTitle,
		BImg,
		BForm,
		BButton,
		ValidationProvider,
		ValidationObserver,
	},
	mixins: [togglePasswordVisibility],
	data() {
		return {
			status: '',
			// password: 'password',
			// userEmail: 'gj@leavingstone.com',
			password: '',
			userEmail: '',
			sideImg: require('@/assets/images/pages/login-v2.svg'),
			// validation rulesimport store from '@/store/index'
			required,
			email,
		};
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
		},
		imgUrl() {
			if (store.state.appConfig.layout.skin === 'dark') {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
				return this.sideImg;
			}
			return this.sideImg;
		},
	},
	methods: {
		login() {
			this.$refs.loginValidation
				.validate()
				.then((success) => {
					if (success) {
						this.$store
							.dispatch('auth/login', {
								email: this.userEmail,
								password: this.password,
							})
							.then((response) => {
								const parsedToken = this.parseJwt(response.data.data.token);
								localStorage.setItem('userInfo', JSON.stringify(parsedToken));
								this.$router.go();
							});
					}
				})
				.catch((err) => {
					// eslint-disable-next-line no-alert
					alert(err.response.message);
					// eslint-disable-next-line no-console
					console.log('error happened', err.response);
				});
		},

		parseJwt(token) {
			const base64Url = token.split('.')[1];
			const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			const jsonPayload = decodeURIComponent(
				atob(base64)
					.split('')
					.map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
					.join('')
			);

			return JSON.parse(jsonPayload);
		},
		validationForm() {
			this.$refs.loginValidation.validate().then((success) => {
				if (success) {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Form Submitted',
							icon: 'EditIcon',
							variant: 'success',
						},
					});
				}
			});
		},
	},
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
